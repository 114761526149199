import React from 'react'
import Img from 'gatsby-image/withIEPolyfill'

const ImageWithCaption = ({ caption, className, title, fullWidth, image, ...props }) =>
  image ? (
    <figure
      className={`flex flex-wrap justify-end my-4 md:my-12 first:mt-8 last: mb-8 mx-auto max-w-${
        fullWidth ? 'none' : '3xl'
      } ${className}`}
      {...props}
    >
      <Img className={`w-full rounded`} fluid={image} title={title} alt={title} />
      {(caption || title) && (
        <figcaption className={`pt-4 md:pt-6 w-full max-w-md md:pr-6 md:pl-0 text-right`}>
          {title && <h4 className="text-xl md:text-2xl">{title}</h4>}
          {caption && <p className="font-heading text-lg mt-2 mb-1">{caption}</p>}
          <div className="w-32 border-b-4 border-gray-800 inline-block rounded"></div>
        </figcaption>
      )}
    </figure>
  ) : null

ImageWithCaption.defaultProps = {
  caption: '',
  className: '',
  fullWidth: false,
  image: null,
  title: '',
}

export { ImageWithCaption }
